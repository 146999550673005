<template>
    <main class="main-wrapper">
        <!-- Start Checkout Area  -->
        <div class="axil-checkout-area axil-section-gap">
            <div class="container">
                <form action="#" v-on:submit.prevent="sendOrder">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="axil-checkout-billing">
                                <h4 class="title mb--40">
                                    {{ $t("Szállítási adatok") }}
                                </h4>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label
                                                >{{ $t("Név") }}
                                                <span>*</span></label
                                            >
                                            <input
                                                type="text"
                                                id="first-name"
                                                placeholder="Adam Smith"
                                                v-model="form.name"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label
                                                >{{ $t("E-mail") }}
                                                <span>*</span></label
                                            >
                                            <input
                                                type="email"
                                                id="email"
                                                placeholder="adam.smith@gmail.com"
                                                v-model="form.email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label
                                                style="top: -22px !important;"
                                                >{{ $t("Telefonszám") }}
                                                <span>*</span></label
                                            >
                                            <VuePhoneNumberInput
                                                v-model="phone"
                                                :no-example="true"
                                                default-country-code="HU"
                                                v-on:update="setPhoneUpdate"
                                                :preferred-countries="[
                                                    'HU',
                                                    'DE',
                                                    'SK',
                                                    'FR'
                                                ]"
                                            />
                                            <!--<input
                                                type="tel"
                                                id="phone"
                                                placeholder="+36101234567"
                                                v-model="form.phone"
                                            />-->
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label style="z-index: unset !important;"
                                        >{{ $t("Szállítási mód") }}
                                        <span>*</span></label
                                    >
                                    <select
                                        id="Region"
                                        v-model="form.shipment_method"
                                    >
                                        <option
                                            v-for="(item, index) in shipments"
                                            v-bind:key="'ship-' + index"
                                            :value="index"
                                        >
                                            {{ item }}
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="form-group"
                                    v-if="
                                        form.shipment_method ===
                                            'Foxpost csomagpont'
                                    "
                                >
                                    <label style="z-index: unset !important;"
                                        >{{ $t("Foxpost csomagpont") }}
                                        <span>*</span></label
                                    >
                                    <select id="foxpost" v-model="form.foxpost">
                                        <option
                                            v-for="(item, index) in foxpost"
                                            v-bind:key="'ship-' + index"
                                            :value="item.value"
                                        >
                                            {{ item.text }}
                                        </option>
                                    </select>
                                </div>
                                <template
                                    v-if="
                                        form.shipment_method ===
                                            'Foxpost házhozszállítás' ||
                                            form.shipment_method ===
                                                'MPL házhozszállítás'
                                    "
                                >
                                    <div class="form-group">
                                        <label
                                            style="z-index: unset !important;"
                                            >{{ $t("Irányítószám") }}
                                            <span>*</span></label
                                        >
                                        <input
                                            type="text"
                                            id="zip"
                                            v-model="form.zip"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label
                                            style="z-index: unset !important;"
                                            >{{ $t("Város") }}
                                            <span>*</span></label
                                        >
                                        <input
                                            type="text"
                                            id="town"
                                            v-model="form.city"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label
                                            style="z-index: unset !important;"
                                            >{{ $t("Utca / házszám") }}
                                            <span>*</span></label
                                        >
                                        <input
                                            type="text"
                                            id="address1"
                                            class="mb--15"
                                            :placeholder="$t('Utca / házszám')"
                                            v-model="form.address"
                                        />
                                    </div>
                                </template>
                                <div class="form-group">
                                    <label style="z-index: unset !important;">{{
                                        $t("Megjegyzés")
                                    }}</label>
                                    <textarea
                                        id="notes"
                                        rows="2"
                                        v-model="form.note"
                                        :placeholder="
                                            $t(
                                                'Megjegyzés a rendeléssel kapcsolatban'
                                            )
                                        "
                                    ></textarea>
                                </div>
                                <div class="form-group input-group">
                                    <input
                                        type="checkbox"
                                        id="aszf"
                                        name="terms-privacy"
                                        v-model="form.aszf"
                                    />
                                    <label for="aszf">{{
                                        $t(
                                            "Rendelésemmel elfogadom az adatvédelmi nyilatkozatot és az általános szerződési feltételeket."
                                        )
                                    }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div
                                class="axil-order-summery order-checkout-summery"
                            >
                                <h5 class="title mb--20">
                                    {{ $t("Termékek") }}
                                </h5>
                                <div class="summery-table-wrap">
                                    <table class="table summery-table">
                                        <thead>
                                            <tr>
                                                <th>{{ $t("Termék") }}</th>
                                                <th>{{ $t("Részösszeg") }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                class="order-product"
                                                v-for="(item, index) in cart"
                                                v-bind:key="
                                                    'cart-item-' + index
                                                "
                                            >
                                                <td style="text-wrap: balance">
                                                    {{ item.name }}
                                                    <i
                                                        v-if="item.is_foil"
                                                        class="fa fa-star"
                                                    />
                                                    &nbsp;
                                                    <span class="quantity"
                                                        >x{{
                                                            item.quantity
                                                        }}</span
                                                    >
                                                </td>
                                                <td>
                                                    {{ item.price_formatted }}
                                                </td>
                                            </tr>
                                            <tr class="order-subtotal">
                                                <td>
                                                    {{
                                                        $t("Szállítási költség")
                                                    }}
                                                </td>
                                                <td
                                                    v-if="
                                                        form.shipment_method ===
                                                            'Foxpost házhozszállítás' &&
                                                            cartSubTotal
                                                    "
                                                >
                                                    {{ foxpost_home_formatted }}
                                                    Ft
                                                </td>
                                                <td
                                                    v-else-if="
                                                        form.shipment_method ===
                                                            'Foxpost csomagpont' &&
                                                            cartSubTotal
                                                    "
                                                >
                                                    {{
                                                        foxpost_packet_formatted
                                                    }}
                                                    Ft
                                                </td>
                                                <td
                                                    v-else-if="
                                                        form.shipment_method ===
                                                            'MPL házhozszállítás' &&
                                                            cartSubTotal
                                                    "
                                                >
                                                    {{ mpl_home_formatted }}
                                                    Ft
                                                </td>
                                                <td v-else>
                                                    0 Ft
                                                </td>
                                            </tr>
                                            <tr
                                                class="order-subtotal"
                                                v-if="form.credit > 0"
                                            >
                                                <td>
                                                    {{
                                                        $t(
                                                            "Felhasználható kredit"
                                                        )
                                                    }}
                                                </td>
                                                <td>
                                                    {{ form.credit }}
                                                    Ft
                                                </td>
                                            </tr>
                                            <tr class="order-subtotal">
                                                <td>{{ $t("Részösszeg") }}</td>
                                                <td>
                                                    {{ totalPaymentWoS }} Ft
                                                </td>
                                            </tr>
                                            <tr class="order-shipping">
                                                <td colspan="2">
                                                    <div
                                                        class="shipping-amount"
                                                    >
                                                        <span class="title">{{
                                                            $t("Fizetési mód")
                                                        }}</span>
                                                    </div>
                                                    <div
                                                        class="input-group"
                                                        v-for="(item,
                                                        index) in payOptions"
                                                        v-bind:key="
                                                            'pay-opt-' + index
                                                        "
                                                    >
                                                        <input
                                                            type="radio"
                                                            :id="
                                                                'radio' + index
                                                            "
                                                            name="payment_method"
                                                            :value="item"
                                                            :checked="
                                                                item ===
                                                                    form.payment_method
                                                            "
                                                            v-model="
                                                                form.payment_method
                                                            "
                                                        />
                                                        <label
                                                            :for="
                                                                'radio' + index
                                                            "
                                                            >{{ item }}</label
                                                        >
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="order-total">
                                                <td>{{ $t("Összesen") }}</td>
                                                <td class="order-total-amount">
                                                    {{ totalPayment }} Ft
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    type="submit"
                                    :disabled="orderSent"
                                    v-if="totalPaymentWoS >= 500"
                                    class="axil-btn btn-bg-primary checkout-btn"
                                >
                                    {{ $t("Megrendelés") }}
                                </button>
                                <p v-else>
                                    {{ $t("Minimum rendelés: 500 Ft") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- End Checkout Area  -->
    </main>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    name: "Checkout",
    components: { VuePhoneNumberInput },
    data() {
        return {
            form: {
                email: null,
                phone: null,
                name: null,
                note: null,
                payment_method: "Készpénz",
                shipment_method: "Személyes átvétel",
                foxpost: 1,
                aszf: false,
                buyer_id: null,
                credit: 0
            },
            phone: null,
            orderSent: false,
            shipments: {
                "Foxpost házhozszállítás": "Foxpost házhozszállítás",
                "Foxpost csomagpont": "Foxpost csomagpont",
                "MPL házhozszállítás": "MPL házhozszállítás",
                "Személyes átvétel": "Személyes átvétel"
            },
            foxpost_home: null,
            foxpost_home_formatted: null,
            foxpost_packet: null,
            foxpost_packet_formatted: null,
            mpl_home: null,
            mpl_home_formatted: null,
            foxpost: [],
            cart: {}
        };
    },
    beforeMount() {
        this.getFoxpost();

        ApiService.get("system/foxpost-price").then(resp => {
            this.foxpost_home = parseInt(resp.data.home);
            this.foxpost_home_formatted = resp.data.home_formatted;
            this.foxpost_packet = parseInt(resp.data.packet);
            this.foxpost_packet_formatted = resp.data.packet_formatted;
            this.mpl_home = parseInt(resp.data.mpl_home);
            this.mpl_home_formatted = resp.data.mpl_home_formatted;
        });

        const self = this;
        const cart = this.$cookies.get("cart");

        ApiService.post(`/cart/update`, { cart: cart, no_popup: true }).then(
            resp => {
                self.cart = resp.data;
                self.isUpdating = false;
            }
        );
    },
    computed: {
        cartSubTotal() {
            let total = 0;
            for (const it in this.cart) {
                total +=
                    parseInt(this.cart[it].price) *
                    parseInt(this.cart[it].quantity);
            }

            total -= this.form.credit;

            return total < 30000;
        },
        totalPayment() {
            let total = 0;
            for (const it in this.cart) {
                total +=
                    parseInt(this.cart[it].price) *
                    parseInt(this.cart[it].quantity);
            }

            if (
                this.form.shipment_method === "Foxpost házhozszállítás" &&
                total < 30000
            ) {
                total += this.foxpost_home;
            }

            if (
                this.form.shipment_method === "Foxpost csomagpont" &&
                total < 30000
            ) {
                total += this.foxpost_packet;
            }

            if (this.form.shipment_method === "MPL házhozszállítás") {
                total += this.mpl_home;
            }

            total -= this.form.credit;

            return total;
        },
        totalPaymentWoS() {
            let total = 0;
            for (const it in this.cart) {
                total +=
                    parseInt(this.cart[it].price) *
                    parseInt(this.cart[it].quantity);
            }

            total -= this.form.credit;

            return total;
        },
        payOptions() {
            if (
                this.form.shipment_method === "Foxpost házhozszállítás" ||
                this.form.shipment_method === "Foxpost csomagpont" ||
                this.form.shipment_method === "MPL házhozszállítás"
            ) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.form.payment_method = "Átutalás";
                return {
                    Átutalás: this.$translate.t("Átutalás")
                };
            }

            return {
                Átutalás: this.$translate.t("Átutalás"),
                Készpénz: this.$translate.t("Készpénz")
            };
        }
    },
    methods: {
        setPhoneUpdate(data) {
            this.form.phone = data.formattedNumber;
        },
        getCsomagPont(id) {
            const self = this;
            for (const it in self.foxpost) {
                console.log(self.foxpost[it].value + "----" + id);
                if (self.foxpost[it].value === id) {
                    console.log(self.foxpost[it]);
                    return self.foxpost[it].text;
                }
            }
            return null;
        },
        sendOrder() {
            if (this.totalPaymentWoS < 500) {
                alert("Minimum rendelés: 500 Ft");
            }
            const self = this;
            self.orderSent = true;
            ApiService.post(`order/do`, {
                form: this.form,
                cart: this.cart
            })
                .then(response => {
                    self.form = {
                        email: null,
                        phone: null,
                        name: null,
                        payment_method: "Készpénz",
                        shipment_method: "Személyes átvétel",
                        foxpost: 1
                    };

                    self.cart = {};
                    self.isUpdating = true;

                    self.$cookies.set(
                        "cart",
                        JSON.stringify(self.cart),
                        60 * 60 * 12
                    );
                    ApiService.post(`/cart/update`, {
                        cart: self.cart,
                        no_popup: true
                    }).then(resp => {
                        self.cart = resp.data;
                        self.isUpdating = false;
                        self.$root.$emit("updateCart", true);
                    });

                    self.$gtag.purchase({
                        transaction_id: response.data.order.id,
                        value: response.data.order.total_price_unformatted
                    });

                    self.orderSent = false;
                    self.$router.push({ name: "SuccessOrder" });
                })
                .finally(() => {
                    self.orderSent = false;
                });
        },
        getFoxpost: function() {
            ApiService.query(`card/foxpost`).then(resp => {
                let helper = [];
                for (const it in resp.data) {
                    helper.push({
                        value: resp.data[it].id,
                        text:
                            resp.data[it].name +
                            " (" +
                            resp.data[it].address +
                            ")"
                    });
                }
                this.foxpost = helper;
            });
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        jQuery(".cart-close").click();

        if (this.$cookies.get("user")) {
            const user = this.$cookies.get("user");
            this.form.email = user.email;
            this.form.name = user.name;
            if (user && user.shipping) {
                this.form.phone = user.shipping.phone;
                this.phone = user.shipping.phone;
                this.form.zip = user.shipping.zip;
                this.form.city = user.shipping.city;
                this.form.address = user.shipping.address;
            }
            this.form.buyer_id = user.id;
            this.form.credit = user.credit;
        }

        this.$gtag.event("begin_checkout", {
            event_category: "ecommerce",
            event_label: "begin_checkout",
            value: true
        });
    }
};
</script>

<style>
.country-selector__country-flag {
    z-index: 40 !important;
}

.input-tel__input {
    height: 55px !important;
    min-height: 55px !important;
}

.country-selector__input {
    height: 55px !important;
    min-height: 55px !important;
}
</style>
